$primary: #87544E;

@import '~bootstrap/scss/bootstrap';

@import 'mixins';

html, body, #root {
    @include fullWidthHeight();
}

body {
    overflow-y: scroll !important;
}

.kfa-map {
    .leaflet-container {
        height: 400px;
    }
}